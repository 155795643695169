<template>
  <b-sidebar
    id="add-new-stage-sidebar"
    :visible="isAddNewDocumentTypeSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="
      (val) => $emit('update:is-add-new-document-type-sidebar-active', val)
    "
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">{{ button_text }}項目檔案</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- 項目檔案-->
            <validation-provider
              #default="validationContext"
              name="項目檔案"
              rules="required"
            >
              <b-form-group label="項目檔案" label-for="name">
                <b-form-input
                  id="name"
                  v-model="documenttypeData.name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="項目檔案"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- 描述 -->
            <validation-provider
              #default="validationContext"
              name="描述"
              rules="required"
            >
              <b-form-group label="描述" label-for="description">
                <b-form-textarea
                  id="description"
                  v-model="documenttypeData.description"
                  placeholder="描述"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  style="display: none"
                />
                <quill-editor v-model="documenttypeData.description" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ button_text }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </b-overlay>
      </validation-observer>
    </template></b-sidebar
  >
</template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BFormCheckbox,
  BOverlay,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import { max } from "@/libs/validations.js";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    vSelect,
    BOverlay,
    BInputGroup,
    BInputGroupAppend,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewDocumentTypeSidebarActive",
    event: "update:is-add-new-document-type-sidebar-active",
  },
  props: {
    isAddNewDocumentTypeSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      max,
    };
  },
  setup(props, { emit }) {
    const loading = ref(false);

    const blankDocumentTypeData = {
      id: 0,
      name: "",
      description: ""
    };
    const button_text = ref("新增");

    const documenttypeData = ref(
      JSON.parse(JSON.stringify(blankDocumentTypeData))
    );

    const resetdocumenttypeData = () => {
      documenttypeData.value = JSON.parse(
        JSON.stringify(blankDocumentTypeData)
      );
    };

    const toast = useToast();

    watch(
      () => [props.id, props.isAddNewDocumentTypeSidebarActive],
      () => {
        if (props.id > 0 && props.isAddNewDocumentTypeSidebarActive) {
          documenttypeData.value = {...props.data}
          if (documenttypeData.value.id > 0) {
            button_text.value = "修改";
          } else {
            button_text.value = "新增";
          }
        } else {
          button_text.value = "新增";
        }
      }
    );

    const onSubmit = () => {
      loading.value = true;
      store
        .dispatch(
          "setting-documenttype/addDocumentType",
          documenttypeData.value
        )
        .then((response) => {
          loading.value = false;
          emit("update:is-add-new-document-type-sidebar-active", false);
          emit("refetch-data");
          toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              text: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          loading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Fail",
              text: error.response.data.message,
              icon: "HeartIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetdocumenttypeData
    );

    return {
      documenttypeData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      button_text,
      loading,
    };
  },
};
</script>

</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#add-new-stage-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.ql-editor {
  height: 300px;
}

.input-group-text{
  padding:0 1rem;
}
.demo_button{
  width:100%;
  min-height:35px;
}
</style>