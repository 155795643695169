import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDocumentTypes(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axiosIns.get('/document_types/list', {params:queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
        })
    },
    addDocumentType(ctx, data) {
        return new Promise((resolve, reject) => {
            axiosIns.post('/document_types', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    deleteDocumentType(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.delete('/document_types', {params:data})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
